import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = 
    
         [
           
            
            {
                id       : 'dashboard',
                title    : 'Home ',
                translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                url      : '/dashboard',
               
            },
            {
                id       : 'home',
                title    : 'Execute PowerWIP',
                translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                url      : '/home', 
            },
       
            {
                id       : 'chartofaccount',
                title    : 'Chart of Accounts',
                translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                url      : '/chartofaccount',
                hidden:true
            },
            {
                id       : 'wipconfiguration',
                title    : 'WIP Configuration',
                translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                url      : '/wipconfiguration',
            },
          
            
            
        ]
    

